// export const BACKEND_URL = "http://localhost:5000"
// export const BACKEND_URL = "https://venturevibe-server.onrender.com"
export const BACKEND_URL = "https://venturevibe-server.onrender.com";

export const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};
