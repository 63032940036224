import React from 'react';
import '../styles/Spiner.css';

const Spiner = () => {
  return (
    <span class="loader"></span>
  );
};

export default Spiner;
